#form-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 45px 0 45px 0;
}

#wrapper-text {
    font-size: 15px;
    font-weight: 400;
    color: #666;
    margin-bottom: 20px;
}

#wrapper-title {
    font-size: 20px;
    font-weight: 600;
}

#wrapper-children {
    width: 80%;
}
