.update-goal-form-parent {
    display: flex;
    justify-content: center;
    min-height: 400px;
    width: 550px;
}

.update-goal-form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.update-goal-buttons-container {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

#update-goal-form-back {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    border: 2px solid rgb(0, 102, 238);
    padding: 12px 20px;
    font-size: 15px;
    width: 160px;
    height: 56px;
    font-weight: 600;
    border-radius: 5px;
    background-color: white;
    color: rgb(0, 102, 238);
    letter-spacing: .015625rem;
    transition: background-color 0.3s ease;
}

#update-goal-form-next {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    border: 2px solid rgb(0, 102, 238);
    width: 160px;
    height: 56px;
    padding: 12px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    background-color: rgb(0, 102, 238);
    color: white;
    letter-spacing: .045625rem;
    transition: background-color 0.3s ease;
}

#update-goal-form-back:hover {
    background-color: rgba(0, 102, 238, 0.1);
}

#update-goal-form-next:hover {
    background-color: rgb(0, 72, 166);
}
