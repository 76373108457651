.exercise-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    min-width: 685px;
    max-width: 775px;
    min-height: 310px;
    justify-content: space-between;
}

.exercise-type-select {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 0 0 0 0;
    font-size: 12px;
    font-weight: 600;
}

.exercise-type-select:focus {
    outline: none;
}


.exercise-card-no-exercises {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
}

.edit-exercise-panel-parent {
    position: relative;
    width: 35%;
    height: 308px;
}

.exercise-card {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.all-exercise-cards {
    border: 1px solid black;
    min-width: 480px;
    position: relative;
}

td {
    text-align: center;
    background-color: rgb(235, 235, 235);
    font-size: 12px;
}

tr {
    border-bottom: 1px solid white;
}

.my-exercise-table {
    border-collapse: collapse;
    width: 100%;
    font-family: "Inter",
        Helvetica,
        Arial,
        sans-serif;
}

.pagination-my-exercise-button-container {
    position: absolute;
    width: 100%;
    bottom: 2px;
    display: flex;
    justify-content: center;
}

.previous-my-exercise-button:disabled,
.next-my-exercise-button:disabled {
    color: rgb(235, 235, 235);
}

.previous-my-exercise-button:disabled:hover,
.next-my-exercise-button:disabled:hover {
    cursor: default;
    text-decoration: none;
}

.previous-my-exercise-button,
.next-my-exercise-button {
    margin: 0;
    padding: auto;
    border: none;
    background-color: transparent;
    color: rgb(0, 102, 238);
}

.previous-my-exercise-button:hover,
.next-my-exercise-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.my-exercise-text {
    display: flex;
    padding: 5px;
    justify-content: center;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.my-exercise-title-container {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: flex-start;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}
