.login-form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    width: 550px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 32px;
    min-height: 500px;
    border-radius: 12px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.login-form-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid rgb(0, 102, 238);
}

.login-submit-button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 60%;
    margin-bottom: 50px;
}

.login-submit-button {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    border: none;
    padding: 15px;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    background-color: rgb(0, 102, 238);
    color: white;
    letter-spacing: .045625rem;
    transition: background-color 0.3s ease;
}

.login-submit-button:disabled:hover,
.login-submit-button:disabled {
    background-color: #8C8C8C;
    cursor: not-allowed;
}

.login-submit-button:hover {
    background-color: rgb(0, 72, 166);
}


.demo-login-container {
    display: flex;
    margin-top: 20px;
}


.demo-login-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    color: rgb(0, 102, 238);
}

.demo-login-button:hover {
    text-decoration: underline;
}

.login-errors {
    color: red;
    font-size: 14px;
    height: 40px;
}
