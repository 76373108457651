.goal-container {
    display: flex;
    margin-top: 10px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: center;
    padding: 15px
}

.goal-content {
    width: 70%;
    border: 1px solid black;
    background-color: rgb(246, 246, 246);
    min-width: 685px;
    max-width: 775px;
}

.goal-container-title {
    display: flex;
    justify-content: flex-start;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}

.goal-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
}

.update-goal-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 10px 10px
}

.goal-nums {
    font-size: 18px;
    font-weight: 600;
}
