.current-weight-input {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 30px;
}

.target-weight-input {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.weight-input-text-1 {
    font-size: 18px;
    font-weight: 600;
}

.weight-input-text-2 {
    font-size: 12px;
    color: #8e8e8e;
}
