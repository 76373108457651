.cardio-log-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.cardio-log-container {
    border: 3px solid black;
    background-color: rgb(240, 240, 240);
}


.cardio-log-labels {
    font-weight: 600;
}

.cardio-log-labels>input,
.cardio-log-labels>select {
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
}

.scrollable-cardio-exercises {
    position: absolute;
    top: 72px;
    width: 80.5%;
    gap: 2px;
    max-height: 100px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.cardio-exercise-list-item {
    cursor: pointer;
    padding: 0;
    padding-top: 2px;
    font-size: 12px;
    padding-bottom: 2px;
    border: none;
    color: black;
    background-color: transparent;
    font-family: "Inter",
        Helvetica,
        Arial,
        sans-serif;
    ;
}

.cardio-exercise-list-item:hover {
    background-color: rgb(240, 240, 240);
}

.duration-cb {
    width: 50px;
}

.cardio-log-submit-button {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    background-color: rgb(133, 196, 0);
    padding: 5px;
    color: white;
    font-weight: 600;
    border: none;
}


.cardio-log-submit-button:hover {
    background-color: rgb(72, 104, 2);
}

.cardio-log-submit-button:disabled,
.cardio-log-submit-button:disabled:hover {
    background-color: #8C8C8C;
    cursor: not-allowed;
}
