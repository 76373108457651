.side-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    transition: transform 2s ease-in-out;
    border: 1px solid black
}


.edit-food-panel-title-container {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: space-between;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}

.close-panel {
    cursor: pointer;
    font-weight: 1000;
}

.edit-food-form {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.edit-food-panel-label {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 15px;
}

.serving-size-label {
    display: flex;
    flex-direction: column;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
    gap: 3px;
}

.serving-size-inputs {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.edit-food-panel-submit-button {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    background-color: rgb(133, 196, 0);
    width: 65%;
    padding: 5px;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 3px;
}

.edit-food-panel-submit-button:hover {
    background-color: rgb(72, 104, 2);
}

.edit-food-panel-strength-text-container {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: center;
    font-size: 10px;
}

.edit-food-panel-submit-button:disabled,
.edit-food-panel-submit-button:disabled:hover {
    background-color: #8C8C8C;
    cursor: not-allowed;
}

.edit-food-panel-submit-button-container {
    display: flex;
    justify-content: center;
}
