/* TODO Add site wide styles */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
li,
fieldset,
form,
label,
legend,
footer,
header,
menu,
nav {
    margin: 0;
    padding: 0;
    border: 0;
}
