.diary-container {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.diary-inner-details-container {
    display: flex;
    margin: 15px 40px;
    justify-content: space-between;
}

.date-picker {
    display: flex;
    width: 150px;
    height: 17px;
    justify-content: center;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgb(140, 140, 140);
    border-right: 1px solid rgb(140, 140, 140);
    border-top: 1px solid rgb(0, 102, 238);
    border-bottom: 1px solid rgb(0, 102, 238);
}

.next-date-button {
    cursor: pointer;
    height: 30px;
    border-radius: 0 5px 5px 0;
    background-color: rgb(0, 102, 238);
    border: none;
    width: 25px;
}

.next-date-button:disabled, .next-date-button:disabled:hover {
    height: 30px;
    cursor: not-allowed;
    border-radius: 0 5px 5px 0;
    background-color: rgb(140, 140, 140);
    border: none;
    width: 25px;
    opacity: 0.5;
}

.prev-date-button {
    cursor: pointer;
    height: 30px;
    border-radius: 5px 0 0 5px;
    background-color: rgb(0, 102, 238);
    border: none;
    width: 25px;
}

.next-date-button:hover, .prev-date-button:hover {
    background-color: rgb(0, 72, 166);
}

.diary-calendar-container {
    display: flex;
    margin-top: 25px;
    height: 50px;
    border-radius: 2px;
}

.diary-elements-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 40px;
    min-width: 685px;
    max-width: 775px;
}

.log-buttons-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.diary-details-title {
    display: flex;
    justify-content: flex-start;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}


.diary-details-container {
    display: flex;
    background-color: rgb(246, 246, 246);
    flex-direction: column;
    border: 1px solid black;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.diary-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.calories-progress-bar-container {
    display: flex;
    justify-content: center;
    height: 30px;
    margin-bottom: 5px;
}

.progress-bar {
    width: 90%;
    height: 20px;
}


.progress-bar[value] {
    -webkit-appearance: none;
    appearance: none;
}


.progress-bar[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.progress-bar[value]::-webkit-progress-value {
    border-radius: 3px;
    background-color: rgb(133, 196, 0);
}


.calories-green {
    font-size: 45px;
    color: rgb(133, 196, 0);
}

.calories-red {
    font-size: 45px;
    color: rgb(255, 0, 0);
}

.all-diary-logs {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.users-log-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
    padding: 15px;
    gap: 15px;
}

.users-logs {
    display: flex;
    flex-direction: column;
}

.users-cardio-log {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

table {
    width: 500px;
}

tr {
    border-bottom: 1px solid white;
}

th {
    background-color: rgb(0, 102, 238);
    color: white;
    font-size: 12px;
    padding: 4px
}

td {
    text-align: center;
    background-color: rgb(235, 235, 235);
    font-size: 12px;
}


table {
    border-collapse: collapse;
}

.edit-my-exercise-button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}
