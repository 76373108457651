.food-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    min-width: 685px;
    max-width: 775px;
    min-height: 350px;
    margin-bottom: 50px;
    justify-content: space-between;
}

.food-card-no-foods {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
}

.edit-foods-panel-parent {
    position: relative;
    width: 35%;
    height: 350px;
}

.food-card {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.all-food-cards {
    border: 1px solid black;
    min-width: 480px;
    position: relative;
}

td {
    text-align: center;
    background-color: rgb(235, 235, 235);
    font-size: 12px;
}

tr {
    border-bottom: 1px solid white;
}

.my-food-table {
    border-collapse: collapse;
    width: 100%;
    font-family: "Inter",
        Helvetica,
        Arial,
        sans-serif;
}

.pagination-my-foods-button-container {
    position: absolute;
    width: 100%;
    bottom: 2px;
    display: flex;
    justify-content: center;
}

.previous-my-foods-button:disabled,
.next-my-foods-button:disabled {
    color: rgb(235, 235, 235);
}

.previous-my-foods-button:disabled:hover,
.next-my-foods-button:disabled:hover {
    cursor: default;
    text-decoration: none;
}

.previous-my-foods-button,
.next-my-foods-button {
    margin: 0;
    padding: auto;
    border: none;
    background-color: transparent;
    color: rgb(0, 102, 238);
}

.previous-my-foods-button:hover,
.next-my-foods-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.my-food-text {
    display: flex;
    padding: 5px;
    justify-content: center;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.my-foods-title-container {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: flex-start;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}
