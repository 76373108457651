.page-not-found-container {
    display: flex;
    border-top: 3px solid rgb(0, 102, 238);
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.page-not-found-text {
    font-family: "Inter",
        Helvetica,
        Arial,
        sans-serif;
}
