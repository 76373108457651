.landing-page {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
}

.landing-page-title {
    display: flex;
    flex-direction: column;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.landing-page-text {
    display: flex;
    flex-direction: column;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: center;
    color: white;
}

.landing-page-section {
    display: flex;
    margin-top: 10px;
    background-color: rgb(0, 102, 238);
    justify-content: center;
    height: 747px;
}

#get-started-button {
    cursor: pointer;
    margin-top: 25px;
    padding: 12px 25px;
    width: 200px;
    background-color: white;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 15px;
    border: none;
    border-radius: 20px;
    color: rgb(0, 102, 238);
}

#get-started-button:hover {
    background-color: rgb(244, 244, 244);
}
