.food-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    gap: 25px;
}

.food-page-content {
    display: flex;
    border: 1px solid black;
    flex-direction: column;
    width: 60%;
    min-width: 685px;
    max-width: 775px;
}

.food-page-title {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: flex-start;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
}

.create-food-container {
    display: flex;
    justify-content: space-between;
    background-color: rgb(246, 246, 246);
}

.create-food-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    width: 60%;
    max-width: 410px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    justify-content: center;
}

.create-food-text > p {
    font-size: 12px;
}

.create-food-form {
    display: flex;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    flex-direction: column;
    padding: 15px 0 15px 30px;
    min-height: 326px;
    width: 35%;
}


.food-labels {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    gap: 3px;
}

.serving-size-label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    gap: 3px;
}

.serving-size-inputs {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

#servings, #units {
    width: 100px;
}


.create-food-button-container {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.create-food-button {
    display: flex;
    border-radius: 3px;
    justify-content: center;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    align-items: center;
    background-color: rgb(0, 102, 238);
    border: 1px solid rgb(0, 102, 238);
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    height: 30px;
    cursor: pointer;
}

.create-food-button:disabled, .create-food-button:disabled:hover {
    display: flex;
    border-radius: 3px;
    justify-content: center;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    align-items: center;
    background-color: #8c8c8c;
    border: 1px solid #8c8c8c;
    color: white;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    height: 30px;
    cursor: not-allowed;
}

.create-food-button:hover {
    background-color: rgb(0, 72, 166);
}


.can-others-use-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    gap: 3px;
}
