.food-log-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 252px;
    padding: 20px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}

.scrollable-list {
    position: absolute;
    top: 48px;
    width: 80.5%;
    gap: 2px;
    max-height: 100px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.food-log-container {
    border: 3px solid black;
    background-color: rgb(240, 240, 240);
}

.food-log-labels>input,
.food-log-labels>select {
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
}

.servings-input {
    width: 45px
}

.food-log-labels {
    font-weight: 600;
}

.food-options-available {
    cursor: pointer;
    padding: 0;
    padding-top: 2px;
    font-size: 12px;
    padding-bottom: 2px;
    border: none;
    color: black;
    background-color: transparent;
    font-family: "Inter",
        Helvetica,
        Arial,
        sans-serif;
    ;
}

.food-options-available:hover {
    background-color: rgb(240, 240, 240);
}

.food-log-submit-button {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    background-color: rgb(133, 196, 0);
    padding: 5px;
    color: white;
    font-weight: 600;
    border: none;
}

.food-log-submit-button:disabled:hover,
.food-log-submit-button:disabled {
    background-color: #8C8C8C;
    cursor: not-allowed;
}



.food-log-submit-button:hover {
    background-color: rgb(72, 104, 2);
}


