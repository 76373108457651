#signup-progress {
    position: absolute;
    width: 100%;
    height: 5px;
}

.signup-buttons-container {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

#signup-back {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    border: 2px solid rgb(0, 102, 238);
    padding: 12px 20px;
    font-size: 15px;
    width: 181px;
    height: 56px;
    font-weight: 600;
    border-radius: 5px;
    background-color: white;
    color: rgb(0, 102, 238);
    letter-spacing: .015625rem;
    transition: background-color 0.3s ease;
}

#signup-next {
    cursor: pointer;
    font-family: "Inter", Helvetica, Arial, sans-serif;
    border: 2px solid rgb(0, 102, 238);
    width: 181px;
    height: 56px;
    padding: 12px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    background-color: rgb(0, 102, 238);
    color: white;
    letter-spacing: .045625rem;
    transition: background-color 0.3s ease;
}

#signup-back:hover {
    background-color: rgba(0, 102, 238, 0.1);
}

#signup-next:hover {
    background-color: rgb(0, 72, 166);
}


#signup-progress[value] {
    -webkit-appearance: none;
    appearance: none;
}


#signup-progress[value]::-webkit-progress-bar {
    border-radius: 12px 12px 0 0;
    background-color: rgb(188, 188, 192)
}

#signup-progress[value]::-webkit-progress-value {
    border-radius: 12px 0 0 0 ;
    background-color: rgb(0, 102, 238);
}

.signup-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signup-form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    width: 550px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 32px;
    min-height: 500px;
    border-radius: 0 0 12px 12px;
    font-family: "Inter", Helvetica, Arial, sans-serif;
}


.signup-form-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid rgb(0, 102, 238);
}


.all-goals-choices {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#error-message {
    color: red;
    font-size: 14px;
    height: 40px;
}
